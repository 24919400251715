import { Avatar } from 'baseui/avatar';
import { capitalize, chain, get, isNil, pick } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ColorLookup, colors, Text } from '../../globalStyles';
import {
  NextSteps,
  ProviderCampusTeamEventsQuery,
  useProviderCampusTeamEventsQuery,
} from '../../graphQL';
import { getFullName, getFullPreferredName } from '../../modelUtils/users';
import { LoadingWidget } from '../../Pages/Users/Widgets/Loading';
import { Nullable } from '../../types';
import { nameSorter, titleCase } from '../../utils';
import { Icon, IconKey, InlineSVG } from '../Icons';
import { useCurrentProvider } from '../Permissions';
import { transitionEventCopy } from '../Timeline/copy';

const DAY_FORMAT = 'MM/DD/YY';

type CampusTeamEvents = NonNullable<ProviderCampusTeamEventsQuery['providerCampusTeamEvents']>;
type Event = CampusTeamEvents[number]['events'][number];

export const CampusTeamActivity = () => {
  const { patientsMonitoredCount } = useCurrentProvider();
  const { data, loading } = useProviderCampusTeamEventsQuery({
    skip: (patientsMonitoredCount || 0) <= 0,
  });

  if (isNil(patientsMonitoredCount)) {
    return null;
  }

  if (patientsMonitoredCount <= 0) {
    return (
      <>
        <Text.h3>Campus Team Activity</Text.h3>
        <Text.caption className="mb4">
          You are on the campus team of {patientsMonitoredCount} students.
        </Text.caption>
        <Text.body kind="grayText" className="i mb3">
          No student updates.
        </Text.body>
        <Text.body kind="grayText" className="i">
          Join student campus team to see activity.
        </Text.body>
      </>
    );
  }

  return (
    <>
      <Text.h3>Campus Team Activity</Text.h3>
      <Text.caption className="mb3">
        You are on the campus team of {patientsMonitoredCount} students.
      </Text.caption>
      <Text.label className="mb3">Last 2 Weeks</Text.label>
      {loading ? (
        <LoadingWidget />
      ) : (
        <ActivityList campusTeamEvents={data?.providerCampusTeamEvents ?? []} />
      )}
    </>
  );
};

const ACTIVITY_LIST_INIT_LIMIT = 8;

type ActivityListProps = {
  campusTeamEvents: CampusTeamEvents;
};
const ActivityList = ({ campusTeamEvents }: ActivityListProps) => {
  const router = useHistory();
  const [showLimit, setShowLimit] = useState<number>(ACTIVITY_LIST_INIT_LIMIT);

  const groupedByDayThenUser = useMemo(
    () =>
      chain(campusTeamEvents)
        .keyBy(e => e.relationship.user.id)
        .mapValues(({ events, relationship: { relationshipType, user } }) => {
          return chain(events)
            .groupBy(event => moment(event.createdAt).format(DAY_FORMAT))
            .mapValues((es, day) => ({
              day,
              userId: user.id,
              relationshipType,
              preferredName: getFullPreferredName(user),
              name: getFullName(user, { noPreferred: true }),
              photoUrl: user.selfie?.url,
              eventsForDay: es
                .sort((a, b) => a.createdAt.valueOf() - b.createdAt.valueOf())
                .map(event => parseEvent(event))
                .filter((event): event is NonNullable<typeof event> => !!event),
            }))
            .values()
            .value();
        })
        .values()
        .flatten()
        .filter(e => !!e.eventsForDay.length)
        .sort((a, b) => {
          const nameSort: number = nameSorter(a, b);
          const daySort: number = moment(b.day).diff(a.day, 'day');
          // sort by date first, then name if tie
          // this is to make the consecutive grouping function work right
          return daySort === 0 ? nameSort : daySort;
        })
        .value(),
    [campusTeamEvents]
  );

  const consecutiveCards = groupConsecutive(groupedByDayThenUser, {
    keyBy: v => v.userId,
    headerFields: ['name', 'photoUrl', 'preferredName', 'relationshipType', 'userId'],
  });

  const limitedList = consecutiveCards.slice(0, showLimit);
  const hasMore = showLimit < consecutiveCards.length;

  const onLoadMoreClicked = () => {
    setShowLimit(l => Math.min(l + ACTIVITY_LIST_INIT_LIMIT, consecutiveCards.length));
  };

  if (!consecutiveCards.length) {
    return (
      <Text.body kind="grayText" className="i">
        No student updates.
      </Text.body>
    );
  }

  return (
    <>
      <div className="flex flex-column gap-3">
        {limitedList.map((eventsByDay, idx) => (
          <PatientActivityCardContainer
            className="flex flex-row gap-3"
            key={`${idx}-${eventsByDay.groupHeader.name}`}
          >
            <Avatar
              size="1.8rem"
              src={eventsByDay.groupHeader.photoUrl}
              name={eventsByDay.groupHeader.name}
            />
            <div className="flex-1">
              <UserDetailsContainer
                className="mb3"
                onClick={() => router.push(`/users/${eventsByDay.groupHeader.userId}`)}
              >
                <div>
                  <Text.bodySmallBold>{eventsByDay.groupHeader.preferredName}</Text.bodySmallBold>
                  <div className="flex flex-row gap-1 items-center">
                    <Dot
                      size={7}
                      color={
                        eventsByDay.groupHeader.relationshipType === 'universityCollaborator'
                          ? 'success'
                          : 'warning'
                      }
                    />
                    <Text.caption>
                      {eventsByDay.groupHeader.relationshipType === 'universityCollaborator'
                        ? 'Collaborating'
                        : 'Monitoring'}
                    </Text.caption>
                  </div>
                </div>
                <InlineSVG icon="chevron-right" size={16} />
              </UserDetailsContainer>
              <div className="flex flex-column gap-3">
                {eventsByDay.vals.map((idk, k) => (
                  <div key={`${idx}-${idk.day}-${idk.userId}`} className="flex flex-row gap-3">
                    <Text.caption kind="grayText">{idk.day}</Text.caption>
                    <div
                      aria-label={`${idk.name} ${idk.day} events`}
                      className="flex flex-column gap-3"
                    >
                      {idk.eventsForDay.map((event, i) => {
                        return (
                          <EventContainer
                            key={`${k}-${idk.name}-${idk.day}-${i}`}
                            onClick={() => router.push(`/users/${idk.userId}?tab=${event.tab}`)}
                          >
                            <Icon icon={event.icon} alt={event.tag} size={18} />
                            <Text.bodySmall className="mt0">{event.text}</Text.bodySmall>
                          </EventContainer>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </PatientActivityCardContainer>
        ))}
      </div>
      {hasMore && (
        <Text.linkButtonSmall className="b db center mt3" onClick={onLoadMoreClicked}>
          Load more
        </Text.linkButtonSmall>
      )}
    </>
  );
};

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 0.5rem;
`;

const PatientActivityCardContainer = styled.div`
  padding: 1.3rem;
  border: 1px solid ${colors.grey.lightBorder};
  background: white;
`;

const Dot = styled.div<{ size: number; color: ColorLookup }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => Math.floor(size / 2)}px;
  background: ${({ color }) => get(colors, color)};
`;

type ParsedEvent = Event & {
  icon: IconKey;
  text: string;
  tab: string;
};

export const parseEvent = (event: Event, hardcodedPatientName?: string): ParsedEvent | null => {
  const data: any = event.data ?? {};
  switch (event.tag) {
    case 'provider:appointment:no-show':
      return {
        icon: 'iconsBlackApptSvg',
        text: 'Appointment no-show',
        tab: 'appointments',
        ...event,
      };

    case 'provider:care-team-message:sent':
      return {
        icon: 'iconsBlackMessageSvg',
        text: 'New Collaboration message',
        tab: 'messages',
        ...event,
      };
    case 'provider:next-steps:created': {
      const nextStep = transitionEventCopy[(data?.nextStep ?? '') as NextSteps];
      if (!nextStep) return null;
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `Next steps for ${data.careType} care selected: ${nextStep}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'coc-eligibility-form:submitted': {
      return {
        icon: 'iconsBlackCheckSvg',
        text: 'Eligibility form completed',
        tab: 'notes',
        ...event,
      };
    }
    case 'system:care-status:updated':
    case 'provider:care-status:updated': {
      // this wont exist for pre-careFlowV2 stuff
      const ctText = data.careType ? ` ${data.careType} ` : ' ';
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${data.name ?? 'System'} changed${ctText}status from ${titleCase(
          data.from
        )} to ${titleCase(data.to)}`,
        tab: 'overview',
        ...event,
      };
    }
    case 'provider:medication-plan:initiated': {
      return {
        icon: 'iconsBlackRxSvg',
        text: 'Patient medication plan initiated',
        tab: 'notes',
        ...event,
      };
    }
    case 'patient:risk-level:changed': {
      const { noteId, before, after, name: providerName } = data;
      // not due to a note
      if (!noteId || !before || !after) return null;
      return {
        icon: 'iconsBlackChangeStatusSvg',
        text: `${
          providerName ? `${providerName} updated patient risk ` : 'Patient risk updated '
        } from ${capitalize(before)} to ${capitalize(after)}`,
        tab: 'notes',
        ...event,
      };
    }
    default:
      return null;
  }
};

type ConsecutiveGroup<T extends object, K extends string | number, HeaderField extends keyof T> = {
  groupKey: K;
  groupHeader: Pick<T, HeaderField>;
  vals: T[];
};

function groupConsecutive<T extends object, K extends string | number, HeaderField extends keyof T>(
  vals: readonly T[],
  opts: Pick<GetConsecutiveArgs<T, K, HeaderField>, 'keyBy' | 'headerFields'>
): ConsecutiveGroup<T, K, HeaderField>[] {
  return groupConsecutiveHelper(vals, {
    ...opts,
    currIdx: 0,
    acc: [],
    currentGroup: null,
  });
}

type GetConsecutiveArgs<
  T extends object,
  K extends string | number,
  HeaderField extends keyof T
> = {
  acc: ConsecutiveGroup<T, K, HeaderField>[];
  currentGroup: Nullable<ConsecutiveGroup<T, K, HeaderField>>;
  currIdx: number;
  keyBy: (v: T) => K;
  headerFields: HeaderField[];
};

function groupConsecutiveHelper<
  T extends object,
  K extends string | number,
  HeaderField extends keyof T
>(
  vals: readonly T[],
  args: GetConsecutiveArgs<T, K, HeaderField>
): ConsecutiveGroup<T, K, HeaderField>[] {
  const { acc, currentGroup, currIdx, keyBy, headerFields } = args;
  // last hit, after iterating through input
  if (currIdx > vals.length - 1) {
    return currentGroup ? [...acc, currentGroup] : acc;
  }

  const val = vals[currIdx];
  const nextIdx = currIdx + 1;
  const valueKey = keyBy(val);
  const groupHeader = pick(val, headerFields);

  // first hit, creates current group with val - the events for a day for a user
  if (!currentGroup) {
    return groupConsecutiveHelper(vals, {
      ...args,
      currentGroup: { groupKey: valueKey, groupHeader, vals: [val] },
      currIdx: nextIdx,
    });
  }
  const currVals = currentGroup.vals;
  const currKey = currentGroup.groupKey;

  // adds user events from other days to current group as long as the current group key and value key are equal
  // check acc to see if group with curr key exists, then add vals from currentGroup to that group
  const currKeyExists = acc.find(accGroup => accGroup.groupKey === currKey);
  if (currKeyExists) {
    currKeyExists.vals.push(...currVals);
    return groupConsecutiveHelper(vals, {
      ...args,
      acc: [...acc],
      currentGroup: { groupKey: valueKey, groupHeader, vals: [val] },
      currIdx: nextIdx,
    });
  }

  // when current group is complete/ value key doesn't match group key
  // switches to next grouping of vals
  return groupConsecutiveHelper(vals, {
    ...args,
    acc: [...acc, currentGroup],
    currentGroup: { groupKey: valueKey, groupHeader, vals: [val] },
    currIdx: nextIdx,
  });
}
