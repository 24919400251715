import { Notification } from 'baseui/notification';
import { compact } from 'lodash';
import moment from 'moment';
import React, { useRef } from 'react';
import { LoadingPage } from '../../../Components/LoadingOverlay';
import { Scheduler } from '../../../Components/Scheduler';
import {
  AppointmentUserV2Fragment,
  CareType,
  PaymentSource,
  useProviderForCareTypeQuery,
} from '../../../graphQL';
import { isStateCode } from '../../../states';
import { UnexpectedError } from '../../Shared';
import { AppointmentOverview, WizardContentWithBack } from '../Common';
import { PaymentWarning } from '../PaymentWarning';
import { ProviderAvailabilityList } from '../ProviderAvailabilityList';
import { BookingBlocker, BookingWizardProps } from '../types';

type SelectTimeProps = BookingWizardProps & {
  displayAllHours?: boolean;
};

export const SelectTime = ({ prevStep, nextStep, data, displayAllHours }: SelectTimeProps) => {
  const { patient, appointment, appointmentTemplates, reschedulingAppointment } = data;
  const careType = appointment.careType!;
  const apptTemplate = appointmentTemplates[appointment.templateIndex!]!;
  const startDate = useRef(moment());

  const cannotBookViaInsurer =
    !patient.hasEligibleInsurance &&
    patient.careFlows.some(
      f =>
        f.careType === careType &&
        ([PaymentSource.Insurance, PaymentSource.OonInsurance] as PaymentSource[]).includes(
          f.paymentSource
        )
    );

  const cannotBookViaSelfPay =
    !patient.isPaymentMethodValid &&
    patient.careFlows.some(
      f =>
        f.careType === careType &&
        (
          [
            PaymentSource.Insurance,
            PaymentSource.OonInsurance,
            PaymentSource.SelfPay,
          ] as PaymentSource[]
        ).includes(f.paymentSource)
    );

  const {
    data: providerData,
    loading,
    error,
  } = useProviderForCareTypeQuery({
    variables: {
      userId: patient.id,
      careType,
      apptType: appointment.appointmentType!,
      organizationId: patient.organization?.id,
      patientState: isStateCode(patient.primaryAddressState)
        ? patient.primaryAddressState
        : undefined,
    },
    // skip if non-intake (aka use provider network).
    // Restricted profiles will cause exceptions!
    // Restricted profiles can only book intake.
    skip: !hasProviderForCareType(careType, patient),
  });

  const { providerForCareType } = providerData?.adminUser ?? {};

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <UnexpectedError cannotRetry />;
  }

  const blockers = compact([
    cannotBookViaSelfPay && BookingBlocker.MissingPaymentMethod,
    cannotBookViaInsurer && BookingBlocker.InvalidInsurance,
  ]);

  return (
    <>
      <WizardContentWithBack
        onBack={data.reschedulingAppointment ? undefined : () => prevStep()}
        className="pb0"
      >
        {data.didEncounterTimeConflict && (
          <Notification kind="negative" overrides={{ Body: { style: { width: '100%' } } }}>
            Sorry, the time you selected is no longer available. Please pick another time.
          </Notification>
        )}
        <AppointmentOverview
          apptUser={data.patient}
          apptProvider={providerForCareType}
          description={apptTemplate.description}
          duration={apptTemplate.duration}
          reschedule={data.reschedulingAppointment}
          paymentSource={data.patient.careFlows.find(f => f.careType === careType)?.paymentSource}
        />
        {cannotBookViaSelfPay && <PaymentWarning title="Missing valid payment method" />}
        {cannotBookViaInsurer && (
          <PaymentWarning
            title="Missing valid insurance plan"
            details={
              <>
                Providers listed below may <strong>not be</strong> in-network. Please confirm
                manually before booking.
              </>
            }
          />
        )}
      </WizardContentWithBack>
      {/* Issue, the provider being passed in here, isn't showing hours for the specific school, only DGM hours */}
      {providerForCareType && (
        <Scheduler
          initialStartDate={startDate.current}
          providers={[providerForCareType]}
          appointment={appointment}
          user={patient}
          careFlowPaymentSource={
            patient.careFlows.find(f => {
              return f.careType === careType;
            })?.paymentSource
          }
          displayAllHours={displayAllHours}
          reschedulingAppointment={reschedulingAppointment}
          onSelectTime={(time, provider, organizationId, isFeeForServiceTime) =>
            nextStep(prevData => ({
              ...prevData,
              didEncounterTimeConflict: false,
              appointment: {
                ...prevData.appointment,
                time,
                provider,
                organizationId,
                isFeeForServiceTime,
              },
              blockers,
            }))
          }
        />
      )}
      {!providerForCareType && (
        <>
          <ProviderAvailabilityList
            user={patient}
            appointmentTemplate={apptTemplate}
            onBack={prevStep}
            onSelectAvailability={({ startTime, provider, organizationId }) => {
              nextStep(prevData => ({
                ...prevData,
                didEncounterTimeConflict: false,
                appointment: {
                  ...prevData.appointment,
                  provider,
                  time: startTime,
                  organizationId,
                },
                usesProviderNetwork: true,
                blockers,
              }));
            }}
          />
        </>
      )}
    </>
  );
};

const hasProviderForCareType = (careType: CareType, user: AppointmentUserV2Fragment) => {
  const hasProviderLookup: Record<CareType, boolean> = {
    [CareType.Psychiatry]: user.hasProvider,
    [CareType.Therapy]: user.hasTherapist,
  };
  return hasProviderLookup[careType];
};
